import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [health, setHealth] = useState(null);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check API health status
    axios.get('/api/health')
      .then(response => {
        setHealth(response.data);
        setError(null);
      })
      .catch(err => {
        setError('Error connecting to API');
        console.error('API Error:', err);
      });

    // Fetch items from database
    axios.get('/api/items')
      .then(response => {
        setItems(response.data);
      })
      .catch(err => {
        console.error('Error fetching items:', err);
      });
  }, []);

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Welcome to sadefi.com React Frontend</h1>
      
      <div style={{ marginTop: '20px' }}>
        <h2>API Status</h2>
        {error ? (
          <div style={{ color: 'red' }}>{error}</div>
        ) : health ? (
          <div style={{ color: 'green' }}>
            <p>Status: {health.status}</p>
            <p>Domain: {health.domain}</p>
            <p>Version: {health.version}</p>
            <p>Database: {health.database}</p>
          </div>
        ) : (
          <div>Loading API status...</div>
        )}
      </div>

      <div style={{ marginTop: '20px' }}>
        <h2>Database Items</h2>
        {items.length > 0 ? (
          <div>
            {items.map(item => (
              <div key={item.id} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ddd' }}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <small>Created: {new Date(item.created_at).toLocaleString()}</small>
              </div>
            ))}
          </div>
        ) : (
          <div>No items found</div>
        )}
      </div>
    </div>
  );
}

export default App;
